<template>
  <div>
    <flixDashboard>
      <template v-slot:content>
        <div class="flix-container">
          <flixCheckPermissions role="calendar" permission="2" error>
            <div>
              <h3 class="flix-html-h3">{{ $t('message.qrCode') }}</h3>
              <calendarBreadcrumbs :link="'dashboardQrCode'" />
              <qrCode :url="getUrl()" :key="$route.params.id" v-if="$route.params.id != 'all'"/>
            </div>
          </flixCheckPermissions>
        </div>
      </template>
    </flixDashboard>
  </div>
</template>

<script>
export default {
  components: {
    qrCode () { return import('@/components/embed/qrCode') },
    calendarBreadcrumbs () { return import('@/components/assets/calendarBreadcrumbs') }
  },
  data () {
    return {
      variables: this.$getUserVariables()
    }
  },
  methods: {
    getUrl () {
      return this.$flix_url + '/' + this.variables.user.language + '/' + this.variables.user.username + '/' + this.$route.params.id + '/'
    }
  }
}
</script>
